import React from 'react';
import Nav from '../components/NavBarContainer';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();
const secondaryColor = "#2D9CDB";

const About = () => {
  return(
    <div>
    <Nav />
    <main>
    <section className="parallax container-fluid">
        <div className="parallax-inner">
        <h1 className="text-center">About Us</h1>
        </div>
    </section>

    <section className="about">
    <div className="container about-container mt-3 mb-3">
    <h3 className="mb-3 font-weight-bold"><strong>Who We Are</strong></h3>
    <p className="text-xs-center">We're the official news media arm of the nation's legislative body, we are dedicated to providing transparent, insightful, and unbiased coverage of all proceedings within the hallowed halls of our democracy.</p>
    <p className="text-xs-center">With a commitment to journalistic integrity, we strive to keep citizens informed about the decisions, debates, and developments that shape our nation's future. From legislative breakthroughs to in-depth analysis, trust us to be your reliable source for everything happening within the heart of governance.</p>
    <p className="text-xs-center">Join us in our mission to uphold the principles of democracy through informed engagement and accountability.</p>

    <h3 className="mb-3 font-weight-bold"><strong>What Sets Us Apart</strong></h3>
    <p className="text-xs-center">At Legislative News Media, we understand the complexities of legislative processes and the impact they have on society. Our team of experienced journalists and analysts work tirelessly to provide in-depth coverage, offering context, analysis, and expert opinions to help our audience understand the significance of legislative decisions.</p>

    <h3 className="mb-3 font-weight-bold"><strong>Our Coverage</strong></h3>
    <p className="text-xs-center">From local council meetings to national debates, Legislative Insight covers a wide range of legislative matters. Whether it's a new bill being introduced, a heated debate on the floor of parliament, or the passage of landmark legislation, you can count on us to bring you the latest updates and insights.</p>

    <h3 className="mb-3 font-weight-bold"><strong>Transparency and Integrity</strong></h3>
    <p className="text-xs-center">We hold ourselves to the highest standards of journalistic integrity. Our reporting is guided by principles of accuracy, fairness, and impartiality. We strive to present all sides of an issue and to hold those in power accountable for their actions.</p>
    <Link to="/board">
    <button className="btn btn-1 hover-filled-slide-down">
     <span>See Board Members</span>
   </button>
   </Link>
    </div>
    </section>
   <Footer />
  </main>

  </div>
  )
}

export default About;
