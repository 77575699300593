import React, { useState, useEffect } from 'react';
import Nav from '../components/NavBarContainer';
import Footer from '../components/Footer';
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom';
import george from '../assets/images/george.png';
import david from '../assets/images/david.jpeg';
import alex from '../assets/images/alex.jpeg';
import nyitse from '../assets/images/nyiste.png';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const Home = () => {
const [posts, setPosts] = useState([]);
const [loading, setLoading] = useState(false)

  useEffect(() => {
      fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    const url = `https://blog.codefussion.tech/wp-json/wp/v2/posts?per_page=3&context=embed`;
    setLoading(true);
    fetch(url, {
      method: 'GET',
      cache: "force-cache",
    })
      .then(res => res.json())
      .then(res => {
        // console.warn(res);
        setPosts(res);
        setLoading(false);
        if (res.message === 'Resource does not exist') {
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        // console.warn(error);
        // alert(error);
      });
    };

    const showPosts = () => {
        return posts.map((item, index) => {
          // let val = item.yoast_head.split("=");
          // let img = val.find(element => element.includes(".png"));
          // let newImg = img.split("[")
          // let finalImg = JSON.stringify(newImg).split(" ");
          // let fin = JSON.stringify(finalImg[0].trim().substring(4))
          // let res = fin.substring(0, fin.length-5);
          // let finRes = res.replace(/['"]+/g, '');
          return(
            <div className="mb-3 event-column">
            <div className="col">
            <div className="p-3 col-md-4 wrapper">
           <h2 className="upper">Tue Aug</h2>
           <a href="#" className="meetup">{item.title.rendered}</a>
           <h3 className="group text-dark">Church Auditorium</h3>
           <p className="details">
             <span className="row">
               <i className="material-icons md-36 icon">event</i>
               <span className="row-item">
               <time>Tue ANameug 2 at 5:30PM</time>
               </span>
             </span>
             <span className="row">
               <i className="material-icons md-36 icon">room</i>
               <span className="row-item">
               <strong>Yola</strong>
               <br />534 E 1st St
               </span>
             </span>
           </p>
          </div>
          </div>
          </div>
          )
        })
    }

  return(
    <div>
    <Nav />
    <section id="header">
    <div>
    <h3 className="text-uppercase text-center text-light">Be in the know</h3>
    <h1 className="text-capitalize text-center text-light" style={{ marginTop: -18 }}>
    <TypeAnimation
    preRenderFirstString={true}
    cursor={false}
    sequence={[
     // Same substring at the start will only be typed out once, initially
     1000, // wait 1s before replacing "Mice" with "Hamsters"
     'Stay Informed',
     1000,
     'Stay Updated',
     1000,
     'Stay Empowered',
     1000,
     ]}
     wrapper="span"
     speed={50}
     style={{ fontSize: '2em', display: 'inline-block' }}
     repeat={Infinity}
   />
   </h1>
   </div>
    <a href="https://news.legislativenews.org" className="btn btn-1 hover-filled-slide-down">
     <span>Read Latest News</span>
   </a>
  </section>
  <section id="about">
  <div className="container about-container mb-3">
  <h3 className="text-center">Welcome to</h3>
  <h1 className="mb-3"><strong>Legislative Assembly News</strong></h1>
  <p className="text-xs-center">As the official news media arm of the nation's legislative body, we are dedicated to providing transparent, insightful, and unbiased coverage of all proceedings within the hallowed halls of our democracy.</p>
  <p className="text-xs-center">Legislative Assembly News is a specialized form of media reporting dedicated to covering a wide range of legislative activities. This includes reporting on the activities of the legislative branch of government, such as debates, lawmaking processes, and committee hearings. In addition to legislative activities, the news coverage also includes executive activities related to legislation, oversight activities, and judicial activities as they pertain to the legislative process.
  </p>
  <p className="text-xs-center">Join us in our mission to uphold the principles of democracy through informed engagement and accountability.</p>
  </div>
  <div>
  <center>
  <Link to="/about"><button className="btn btn-1 hover-filled-slide-down btn-lg"><span>More About us</span></button></Link>
  <Link to="/board"><button className="btn btn-1 hover-filled-slide-down btn-lg"><span>See Board Members</span></button></Link>
  <a href="https://news.legislativenews.org" className="btn btn-1 hover-filled-slide-down btn-lg"><span>Read Latest News</span></a>
  </center>
  </div>
  </section>

<section id="team" class="team content-section">
  <div class="container">
    <div class="row text-center">
      <div class="col-md-12">
        <h2><strong>Our Board Members</strong></h2>
        <h3>Meet the people who make things happen</h3>
      </div>

      <div class="container">
        <div class="row">

        <div data-aos="fade-up" data-aos-duration="3000" class="col-md-4">
          <div class="team-member">
            <figure>
              <img src={nyitse} alt="" class="img-responsive" />
              <figcaption>
                <p>Amb. Dr. Gowin Iorsheregh Thomas Nyitse was born on the 6th of June, 1971 to the family of Late Sergent Thomas Iorsheregh Nyitse of the Blessed Memory and Mrs. Martina Ishiokorgh Thomas Nyitse in an agrarian community of Tse Atuma Upaande Nyieve Mbakyior, Shereve in Kadarko, in Keana Local Government Area of Nasarawa State.</p>
                <button className="btn btn-1 hover-filled-slide-down" data-bs-toggle="modal" data-bs-target="#nyiste">
                 <span>View profile</span>
               </button>
              </figcaption>
            </figure>
            <h4>Godwin Thomas Nyitse PHD</h4>
            <p> Director / Editor-In-Chief
              Legislative Assembly News </p>
          </div>
        </div>

        <div data-aos="fade-up" data-aos-duration="3000" class="col-md-4">
          <div class="team-member">
            <figure>
              <img src={david} alt="" class="img-responsive" />
              <figcaption>
                <p>Chief Dr. Amb Ebosetale David Aigbefoh Esq is a prominent figure with an impressive array of qualifications and accomplishments.</p>
                <button className="btn btn-1 hover-filled-slide-down" data-bs-toggle="modal" data-bs-target="#david">
                 <span>View profile</span>
               </button>
              </figcaption>
            </figure>
            <h4>Chief Dr. Amb Ebosetale David Aigbefoh Esq</h4>
            <p>Chairman and Publisher</p>
          </div>
        </div>

          <div data-aos="fade-up" data-aos-duration="3000" class="col-md-4">
            <div class="team-member">
              <figure>
                <img src={george} alt="" class="img-responsive" />
                <figcaption>
                  <p>George Korede Martins began his professional career at the United Nations Information Center UNIC Ikoyi Lagos in 1985 and worked with the erstwhile Director, Dr. Joseph Findaley as Communication Strategist.</p>
                  <button className="btn btn-1 hover-filled-slide-down" data-bs-toggle="modal" data-bs-target="#george">
                   <span>View profile</span>
                 </button>
                </figcaption>
              </figure>
              <h4>George Korede Martins</h4>
              <p>Director / Managing Editor </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <center>
  <Link to="/board">
  <button className="btn btn-1 hover-filled-slide-down">
   <span>See All Board Members</span>
 </button>
 </Link>
 </center>
</section>

  {/*<section className="container-fluid">
  <h1 className="mb-3 text-center">Read Latest News</h1>
  {showPosts()}
  </section> */}

  <section>
  <div className="pub">
  <header><h1><strong>Recent Publications</strong></h1></header>
  <main class="gallery" data-aos="fade-up" data-aos-duration="3000">
    <figure>
    <a href="https://legislativenews.org/images/1.jpeg" download>
      <img src="https://legislativenews.org/images/1.jpeg" alt="Legislative Assembly News" />
    </a>
    </figure>
     <figure>
     <a href="https://legislativenews.org/images/2.jpeg" download>
      <img src="https://legislativenews.org/images/2.jpeg" alt="Legislative Assembly News" />
     </a>
    </figure>
     <figure>
     <a href="https://legislativenews.org/images/3.jpeg" download>
      <img src="https://legislativenews.org/images/3.jpeg" alt="Legislative Assembly News" />
    </a>
    </figure>
  </main>
  <Link to="/publications">
  <button className="btn btn-1 hover-filled-slide-down">
   <span>See All Publications</span>
  </button>
  </Link>
</div>
  </section>

  <section className="container-fluid">
  <Footer />
  </section>


    {/* George Modal */}
    <div class="modal fade" id="george" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"><strong>George Korede Martins</strong></h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
        <p>George Korede Martins began his professional career at the United Nations Information Center UNIC Ikoyi Lagos in 1985 and worked with the erstwhile Director, Dr. Joseph Findaley as Communication Strategist.</p>
        <p>He later proceeded to the Nigerian Television Authority Channel 10 Lagos and presented "The Consultants " a Programme that featured Medical Doctors and Health Professionals. He also presented The Ultimate ShowTime.</p>
        <p>In 1992, he featured on Nigeria's all-time best Soap Opera Checkmates on NTA Network as "Barry Hattrope" with the likes of Richard Mofe~Damijo, Ego Boyo, Bob-Manuel Udoku etc and Amaka Igwe as Producer.</p>
        <p>He founded The Ultimate Magazine publication, This accomplished Media Executive was a NewsCaster on both Radio and TV at LivingSprings FM and Head of programs at Vision FM Abuja, all collectively spanning a 25 year career.  ACADEMICS:  BSc. Mass Communication MSc. Marketing and Projects Management PhD. Education Management. From Erasmus University, Rotterdam Holland and at Far Eastern University.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
      </div>
    </div>
    </div>

    {/* David Modal */}
    <div class="modal fade" id="david" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"><strong>Chief Dr. Amb Ebosetale David Aigbefoh Esq</strong></h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
        <p>Chief Dr. Amb Ebosetale David Aigbefoh Esq is a prominent figure with an impressive array of qualifications and accomplishments. He is a private legal practitioner and the author of several notable works, including "Manual on Legal Procedure for Implementation of Resolutions of the Public Accounts Committees of the National Assembly" and "Procedure for Drafting and Domestication of Treaties."</p>

        <p>In addition to his writing, Chief Dr. Aigbefoh is the Chairman and Publisher of "Legislative Assembly News Media Ltd" and "Legislative News Ltd." He also serves as a Director of Programs at the Association of Legislative Drafting and Advocacy Practitioners (ALDRAP) at the National Assembly Complex.</p>

        <p>Furthermore, Chief Dr. Aigbefoh has held various leadership positions, such as Vice Chairman of the Nigerian Bar Association, Gwagwalada Branch, Abuja FCT. He also carries a traditional title as Chief, being turbaned as "IDON GARI" (Eye of the Town).</p>

       <p>Chief Dr. Aigbefoh has received multiple awards both locally and internationally in recognition of his contributions to the legal field. He graduated from the prestigious Kogi State University with an LLB(Hons) in 2008, was called to the Bar by the Body of Benchers in 2010, and has pursued further education including an "Advanced Course in "Practice and Procedure" and "Training on Advanced Legal Writing Skills" at the Nigerian Institute of Advanced Legal Studies (NIALS) 2018 and 2019 Respectively.</p>
       <p>Moreover, Chief Dr. Aigbefoh holds a Master's Degree in Legislative Drafting(IV) from the Nigerian Institute of Legislative and Democratic Studies (NILDS) and a Doctorate Degree from Cornerstone University and Theological Seminary in Jerusalem, Israel (Honoris Causa), adding to his impressive educational credentials. He's Married and a deliberate Patriot.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
    </div>

    {/* Nyitse Modal */}
    <div class="modal fade" id="nyiste" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"><strong>Godwin Thomas Nyitse PHD</strong></h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
        <p>Amb. Dr. Gowin Iorsheregh Thomas Nyitse was born on the 6th of June, 1971 to the family of Late Sergent Thomas Iorsheregh Nyitse of the Blessed Memory and Mrs. Martina Ishiokorgh Thomas Nyitse in an agrarian community of Tse Atuma Upaande Nyieve Mbakyior, Shereve in Kadarko, in Keana Local Government Area of Nasarawa State.</p>

        <p>He started his early Education in St. Anthony Roman Catholic Missionary School, Kadarko Army Day Children School, Rukuba Barracks Jos, Army Day Children School, Serti Baruwa in Gashaka Local Government Area of Gongola State, now, Taraba State. And Igo Primary School Igo, in Ovia South West Local Government Area of Bendel State now, Edo State and completed his First School Leaving Certificate (FSLC) in 1986.</p>

        <p>And thereafter, gain admission into The Army Day Secondary School, Ekenhuawan Barracks in Benin City in Edo State and did his Junior Secondary School from 1987 to 1988 when his father Retired from the Nigerian Army (NA). and they were located to Shendam, in Plateau State. And between 1989 to 1990 he completed his Junior Secondary School with the Governmment Secondary School Shiman Kar in Shendam Local Government Area of Plateau State. Between 1990 to 1993, he completed his Senior Secondary School, (SSCE) with Government Secondary School Shimankar, still in Shendarm LGA of Plateau State.</p>

       <p>Godwin Thomas Iorsheregh Nyitse (JP) spent seven (7) years at home after completing his Senior Secondary School due to reasons beyond his control. </p>
       <p>He attended the College of Arts Science and Technology (CAST), Keffi in the year 2000 affiliated with the Ahmadu Bello Univeristy, Zaria in Kaduna State, for his I.J.M.B.E programme and came out with high grade with eleven (11) points, his Advanced Level subjects combination study was, History, Literature and Government (LA5).</p>
       <p>Between 2001 and 2004, he undertook other academic studies, this time he proceeded to the International Institute of Journalism Abuja where he bagged his National Diploma and Higher National Diploma in Journalism and Mass Communication with Specialization in Public Relations.</p>
       <p>Again, he obtained his National Diploma in Public Administration with the Plateau State Polytechnic in Barkin-Ladi, Plateau State in 2005. Godwin Nyitse between 2009 and 2014, he undertook other Post Graduate Academic Studies, and bagged the following certificates: Post Graduate Diploma in Theology with the ECWA Theological College, now Seminary Karu, FCT, Abuja. Post Graduate Diploma in Education with the FCT College of Education, Affiliated with the Ahmadu Bello University, Zaria, Kaduna State.</p>
       <p>Between 2008 and 2021 he undertook other academic studies which includes: Bachelor of Arts Degree in Journalism and Mass Communication with the Thames Valley University, Brentford London through Distance Learning Programme . And Masters of Arts Degree in Journalism and Mass Communication with specialization in (Print Media) with the Cornerstone University and Theological Seminary Jerusalem, Israel and Doctoral Degree in Philosophy in Mass Communication and Journalism. Areas of research is on Print Media Administrations and Management in a Democracy: The Role of the Media House Owners.</p>
       <p>I was given an Exemption Letter not to perform my one year compulsory, National Youth Service programme on the grounds of my age.</p>
       <p>On the 20th January, 2005, I officially joined the Nigerian Union of Journalist NUJ, as a full member, that is the FCT Chapter and from that time on till date I am still practicing as a Journalist for (19 years) now, and to God be the Glory forever and ever Amen.</p>
       <p>As a versatile scholar of Journalism and Mass Communication Education, has to my credit over 120 scholarly publications in High-Impact Academic Journals, Books and Conference proceedings’, Co-authored Investigative and Interpretative Journalism: An Insight into Critical and Review Writing, Applicable theories in Mass Communication Studies, Mass Media and Society, Sociological Perspectives, Speech Writing and Presentation, Mass Media Writing Techniques. Dr. Godwin Thomas Nyitse has numerous journal articles in reputable Journals to his credit and has equally attended presented papers in both local and foreign conferences. He is a member of the Nigerian Union of Journalist, Nigeria Union of Teachers, National Association of Men Academics (NAMA), Member – Christian Association of Nigeria (CAN), Kwali Chapter, F.C.T., Member – World Editors Forum (WEF).</p>
       <p>His philosophy is “If your life does not make positive impact on others and the society, then, it is not worth living”. Dr. Godwin Thomas Iorsheregh Nyitse has over 2 decades of working experiences as a Journalist and Editor in Nigeria. He has enabled many students to score A’s in their research projects. He is also a member of various international associations and societies in the field of communication and mass media.</p>
       <h3>Honours/Title/Awards</h3>
       <ol>
       <li>International Ambassador for World Peace with Universal Peace Federation, Nigeria 2007.</li>
       <li>Pillar Par Excellence: Tiv Progressive Community, Kuje Branch, Abuja 2015.</li>
       <li>Fellow Chartered Institute of Administration FCTA 2019.</li>
       <li>Certificate of Commendation for Outstanding Performance During his 3 months Teaching Practice (TP) by Dr. Sani Mohammed Ladan, Principal, GSS, Gwagwalada (2018) PGDE.</li>
       <li>Science Engineering Development Institutes, Minna for Professional Good Conduct 2011.</li>
       <li>The Tiv Traditional Council of Chiefs and Traditional Rulers Honour him with a prestigious title of “Gbenda-U-Tiv AMAC (meaning the connecting road to every success) in Abuja Municipal Area Council, FCT, Abuja, on Saturday, 11th December 2021.</li>
       <li>The Tiv Traditional Council of Chiefs/Elders Honour him with the Title of (Special Adviser) Kwali Area Council, FCT-Abuja.</li>
       <li>Jerusalem Pilgrims from June 24 to July 4, 2022. Sponsored by the family of Hon. Danladi Chiya (JP), The Executive Chairman Kwali Area Council and his wife, Mrs. Hannatu Chiya (JP).</li>
       <li>A Gold Medal of Honour by GSS Kuje during their inter-house sports 2024.</li>
       <li>A Gold Medal of Honour by GSS Gwagwalada during their Inter-House Sports 2024.</li>
       <li>A Gold Medal of Honour from Government Junior Day Secondary School, Kwali 2024.</li>
       <li>He was given the title of “The Kakakin Kwali” by the Late HRH Alh. Dr. Shaban Audu Nizazo III, the Etsu of Kwali and Chairman Council of Chiefs, Kwali Area Council, FCT Abuja.</li>
       <li>Media Advisor to H.E. Alh. Dr. Kabiru Wakili El-Kabir, Nasarawa State Governorship Aspirant 2011.</li>
       <li>Media Adviser to the ETSU of Kwali – Late Dr. Alh. Shaban Audu Nizazo III for over 10 years</li>
       <li>Media Adviser to Hon. Tanko Musa Osuku Esq. the Administrator, Kogi Local Government, Kogi state 2019</li>
       <li>Currently – Editor –In-Chief, Legislative Assembly News at the 10th National Assembly Abuja</li>
       <li>Senator Senior Special Adviser to Mrs. Hannatu Danladi Chiya JP -  the First Lady, Kwali Area Council, between 2021 to 2024</li>
       <li>Special Adviser to the Ter Kwali, Zaki Ayough Wundo and Tiv Traditional Council of Chiefs and Elders Kwali from 2017 till date</li>
       <li>Chief Press Secretary to the Ter AMAC – Chief Dr. Stephen Kuma Atam, President Tiv Traditional Council of Chiefs, AMAC</li>
       <li>Special Adviser on Media and Publicity to Rev. Dr. Kyauta Godiri, the Kwali CAN Chairman, DCC Secretary, ECWA Kwali from 2015 till date </li>
       </ol>
       <h3>Publications</h3>
       <ul>
       <li>Published over 200 articles in both Local and International Reputable Journals from 2005 till date</li>
       <li>Contribution over 100 chapters to both Local and International Authors from 2005 till date</li>
       <li>On-going different Research Work from 2005 till date</li>
       <li>The Village Clown (a poem) Published 2017</li>
       <li>Edit and published over 250 copies of different magazines from 2005 till date</li>
       </ul>
       <p>He is happily married to Mrs. Augusta Godwin Nyitse and the union is blessed with five (5) children. Namely:
        Paul Godwin, Daniel Godwin, Jeremiah Godwin, David Godwin and Catherine Godwin.
        </p>
        <h3>Hobbies</h3>
        <p>Hobbies include: Reading and Writing, Watching Animals Documentary, Watching War Movies, Watching Royal, Traditional History Documentaries both English and Hausa, Travelling and farming, Research and Documentation of Tiv rich culture and tradition.</p>
        <p>Amb. Dr. Godwin Thomas Iorsheregh Nyitse (JP) is The Founder and Chairman and Editor-In-Chief of the National Chronicle Newspaper and Magazine. Founder/President of The Chronicle Business School, Abuja, Founder/President, Chronicle Leadership Institute, Abuja.</p>
        <p>Dr. Godwin T. I. Nyitse is widely published and has become an authoritative voice in the field of Journalism and Mass Communication in Nigeria and Africa.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
    </div>

    {/* Becky Modal */}
    <div class="modal fade" id="becky" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"><strong>Becky Anyam</strong></h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
        <p>Becky Anyam was born on January 10, 1990, in Makurdi Local Government Area Benue State, Nigeria.  She obtained her high school education at Mount La Salle College Naka in Benue State and received her tertiary education at the Nassarawa State University, Keffi, Plateau State Polytechnic, Jos, and Nigerian French Language Village, Ajara Badagry, Lagos State Nigeria, among other qualifications, she holds degree of Bachelor of Mass Communication, a Diploma in French Language, and a diploma in  Mass Communication respectively.</p>
       <p>Anyam Becky is a dedicated and accomplished civil servant who has devoted his career to public service. She started her professional career in Jos South Local Government Council, Plateau State in 2009 at Nigeria Television Authority (NTA) during her industrial attachment. She held various roles before becoming the Creative Editor in 2021 where she directed and motivated teams of art directors, illustrated, and wrote to help their talents effectively.</p>
       <p>A consummate and thorough-bred journalist who has consistently demonstrated a strong commitment to upholding the principles of good governance, public welfare, and the rule of law throughout his professional Career. Interestingly, Becky Anyam's track record of achievements in journalism is a testament to her unwavering commitment to her public duties and has recorded remarkable achievements in the course of her career.  Becky Anyam whose hobbies include Reading, sightseeing, and traveling is in good standing, she is widely traveled and serves as the <strong>Creative Editor</strong> for Legislative Assembly News.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
    </div>

    </div>
  )
}

export default Home;
