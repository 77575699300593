import React from 'react';
import Nav from '../components/NavBarContainer';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const Publications = () => {
  return(
    <div>
    <Nav />
  <div class="pub" data-aos="fade-up" data-aos-duration="3000">
  <header><h1><strong>Publications</strong></h1></header>
  <main class="gallery">
    <figure>
    <a href="https://legislativenews.org/images/1.jpeg" download>
      <img src="https://legislativenews.org/images/1.jpeg" alt="Legislative Assembly News" />
    </a>
    </figure>
    <figure>
    <a href="https://legislativenews.org/images/2.jpeg" download>
     <img src="https://legislativenews.org/images/2.jpeg" alt="Legislative Assembly News" />
    </a>
   </figure>
   <figure>
   <a href="https://legislativenews.org/images/3.jpeg" download>
    <img src="https://legislativenews.org/images/3.jpeg" alt="Legislative Assembly News" />
  </a>
  </figure>
  <figure>
  <a href="https://legislativenews.org/images/4.jpeg" download>
   <img src="https://legislativenews.org/images/4.jpeg" alt="Legislative Assembly News" />
 </a>
 </figure>
 <figure>
 <a href="https://legislativenews.org/images/5.jpeg" download>
  <img src="https://legislativenews.org/images/5.jpeg" alt="Legislative Assembly News" />
</a>
</figure>
<figure>
<a href="https://legislativenews.org/images/6.jpeg" download>
 <img src="https://legislativenews.org/images/6.jpeg" alt="Legislative Assembly News" />
</a>
</figure>
</main>
</div>

  <Footer />

    </div>
  )

}

export default Publications
