import React from 'react';
import { Link } from 'react-router-dom';
let date = new Date();
const Footer = () => {
  return(
    <div>

<footer id="footer">
  <div class="col col1">
    <h3 className="text-light">Legislative Assembly News</h3>
    <p className="text-light">Email: info@legislativenewsmedia.org</p>
    <div class="social">
      <a href="#" target="_blank" class="link"><img src="https://assets.codepen.io/9051928/codepen_1.png" alt="" /></a>
      <a href="#" target="_blank" class="link"><img src="https://assets.codepen.io/9051928/x.png" alt="" /></a>
      <a href="#" target="_blank" class="link"><img src="https://assets.codepen.io/9051928/youtube_1.png" alt="" /></a>
    </div>
    <p className="mt-4 text-light font-weight-bold" style={{ fontSize: 'smaller' }}>2024 © All Rights Reserved</p>
  </div>
  <div className="col col2">
    <Link to="/about"><p>About</p></Link>
    <Link to="/board"><p>Board Members</p></Link>
    <p>Privacy Policy</p>
    <p>Terms of service</p>
  </div>
  <div className="col col3">
    <Link to="/publications"><p>Publications</p></Link>
    <Link to="/about"><p>About</p></Link>
    <p>Join our team</p>
    <p>Partner with us</p>
  </div>
</footer>
    </div>
  )
}

export default Footer;
