import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Board from './pages/Board';
import Publications from './pages/Publications';
import LegislativeProceedings from './pages/LegislativeProceedings';
import HouseAssemblyProceedings from './pages/HouseAssemblyProceedings';

const App = () => {
  return (
    <Router>
    <Routes>
    <Route exact path="/" element={<Home />} />
    <Route exact path="/about" element={<About />} />
    <Route exact path="/board" element={<Board />} />
    <Route exact path="/publications" element={<Publications />} />
    <Route exact path="/legislative-proceedings" element={<LegislativeProceedings />} />
    <Route exact path="/house-assembly-proceedings" element={<HouseAssemblyProceedings />} />
    </Routes>
    </Router>
  )
}

export default App;
