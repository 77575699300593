import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Swal from "sweetalert2";
import Nav from '../components/NavBarContainer';
import Footer from '../components/Footer';
const CLOUD_NAME = "codeflare"
const PRESET_NAME = "legislative"


const LegislativeProceedings = () => {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
   setFileName(e.target.files[0])
   setFile(URL.createObjectURL(e.target.files[0]));
 }

 const uploadprofileImageToCloudinary = () => {
     if(fileName === ""){
       Swal.fire({
         title: "Empty",
         text: "Please select a profile image",
         icon: "error",
         confirmButtonText: "OK",
         backdrop: `
         rgba(143, 70, 0, 0.3)
         url("")
         left top
         no-repeat
       `
       })
     }else{
     setLoading(true)
     setDisabled(true);
     let formdata = new FormData();
     formdata.append("file", fileName);
     formdata.append("cloud_name", CLOUD_NAME);
     formdata.append("upload_preset", PRESET_NAME);
     //upload image to cloudinary
     fetch(`https://api.cloudinary.com/v1_1/${CLOUD_NAME}/upload`, {
         method: 'POST',
         body: formdata
       }).then(response => response.json())
         .then(responseJson => {
           console.log(responseJson)
           // uploadImage(responseJson.secure_url)
         }).catch(error => {
           // this.setState({ url: null, isUploading: false })
         })
    }
    }

  return(
    <div>
    <Nav />
    <div className="container">
    <h3><strong>Send Legislative Proceedings</strong></h3>
    <div className="card col-sm-6 p-3">
    <form>
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Full Name</label>
      <input type="text" class="form-control" onChange={(e) =>setName(e.target.value)} aria-describedby="emailHelp" />
    </div>
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Email</label>
      <input type="text" class="form-control" onChange={(e) =>setEmail(e.target.value)} aria-describedby="emailHelp" />
    </div>
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Title</label>
      <input type="text" class="form-control" onChange={(e) =>setTitle(e.target.value)} aria-describedby="emailHelp" />
    </div>
    <input type="file" accept="image/png, image/jpeg" onChange={handleChange} /><br/><br />
    {fileName && <img src={file} className="upload" style={{ width: '50%', height: 'auto' }} alt="" /> }

    <Button className="col-sm-12" disabled={disabled} variant="primary" onClick={()=>uploadprofileImageToCloudinary()}>
    {loading ? (
      <Spinner animation="border" variant="light" size="sm" />
    ) : (
      "Submit"
    )}
    </Button>
    </form>
    </div>
    </div>
    <br /><br /><br />
    <Footer />
    </div>
  )
}

export default LegislativeProceedings;
